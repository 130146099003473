import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { RootState, useAppSelector } from 'src/store';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { IClientSecretResponseDTO } from 'src/api/monri/ResponseDtos';
import { Flex, Input } from 'src/components/common';
import { Spinner } from 'src/components/shared';

export interface MoneriPaymentFormProps {
  clientSecret?: IClientSecretResponseDTO;
  formRef: MutableRefObject<any>;
  paymentSuccessful: (orderNumber: string, panToken: string) => void;
}

export const MonriPaymentForm = ({ clientSecret, formRef, paymentSuccessful }: MoneriPaymentFormProps) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const { user } = useAppSelector((state: RootState) => state.auth);

  const [fullName, setFullName] = useState<string>('');
  const cardRef = useRef(null);

  const style = {
    base: {
      fontSize: '16px',
      color: '#663399',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    label: {
      base: {
        color: '#000000',
        fontSize: '10px',
        fontWeight: 400,
        margin: '0 0 8px 8px',
      },
    },
    input: {
      base: {
        height: '48px',
        padding: '24px 12px',
        borderRadius: '20px',
        border: `1px solid ${theme.colors.palette.lightPurple}`,
        backgroundColor: `rgb(250, 250, 250)`,
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };

  useEffect(() => {
    if (window.Monri && clientSecret != null) {
      // Clear previous card component if it exists
      const cardElement = document.getElementById('card-element');
      if (cardElement) {
        cardElement.innerHTML = '';
      }
      const monri = window.Monri(process.env.REACT_APP_MONRI_TOKEN, {
        locale: 'hr',
        fonts: [
          {
            family: 'Barlow-Regular',
            src: 'https://fonts.googleapis.com/css2?family=Barlow&display=swap',
          },
        ],
      });
      const components = monri.components({ clientSecret: clientSecret.client_secret });
      const card = components.create('card', { style: style, tokenizePan: true });
      cardRef.current = card;
      cardRef.current.mount('card-element');
    }
  }, [clientSecret]);

  useEffect(() => {
    if (!window.Monri || clientSecret == null) return;

    const monri = window.Monri(process.env.REACT_APP_MONRI_TOKEN, {
      locale: 'hr',
      fonts: [
        {
          family: 'Barlow-Regular',
          src: 'https://fonts.googleapis.com/css2?family=Barlow&display=swap',
        },
      ],
    });

    const components = monri.components({ clientSecret: clientSecret.client_secret });
    const card = document.getElementById('card-element');

    if (!card) {
      const card = components.create('card', { style: style, tokenizePan: true });
      cardRef.current = card;
      cardRef.current.mount('card-element');
    }

    const form = document.getElementById('payment-form');

    // Define the submit handler function
    const handleSubmit = (event) => {
      event.preventDefault();

      const params = {
        address: 'xxx',
        fullName: fullName,
        city: user?.city ?? 'xxx',
        zip: '000',
        phone: user?.phone ?? 'xxx',
        country: user?.country ?? 'xxx',
        email: user?.email ?? 'xxx',
        orderInfo: 'Posredovanje za psihoterapijsku sesiju',
      };

      monri.confirmPayment(cardRef.current, params).then((result: any) => {
        handlePaymentResult(result);
      });
    };

    if (window.Monri && clientSecret != null && form) {
      // Add event listener
      form.addEventListener('submit', handleSubmit);
    }

    // Cleanup function to remove the listener
    return () => {
      if (form) {
        form.removeEventListener('submit', handleSubmit);
      }
    };
  }, [clientSecret, fullName, user]);

  const handlePaymentResult = async (result: any) => {
    if (result.error) {
      ToastNotifications.error(result.error.message, 'confirmPayment');
    } else {
      if (result.result.status == 'approved') {
        paymentSuccessful(clientSecret.order_number, result.result.pan_token);
      } else {
        ToastNotifications.error(t('error_payment_failed'), 'confirmPayment');
      }
    }
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Input
        width={'100%'}
        margin={'24px 0'}
        label={'IME I PREZIME NA KARTICI'}
        placeholder={'Ime i prezime'}
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
      />

      {!clientSecret && (
        <Flex margin="24px 0" width="100%" justifyContent="center">
          <Spinner size={40} color={theme.colors.palette.purple} inline />
        </Flex>
      )}

      <form ref={formRef} id="payment-form" style={{ width: '100%' }}>
        <div id="card-element" style={{ width: '100%', boxSizing: 'border-box' }}></div>
      </form>
    </Flex>
  );
};
