import React, { ReactElement, useState, useRef, useEffect, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { useNavigate, NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Flex, Text } from 'src/components/common';
import { RoutesEnum, getAppRoutes } from 'src/routes';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { logout, toggleLoginModal } from 'src/store/slices/authSlice';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import MenuIcon from 'src/assets/images/menu.png';
import CloseIcon from 'src/assets/images/close.png';

const BurgerMenuWrapper = styled.div`
  display: none;
  overflow: auto;

  .MuiList-root {
    max-width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    right: 16px;
  }
`;

const CustomMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const MenuItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const BurgerMenu = (): ReactElement => {
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, user } = useAppSelector((state: RootState) => state.auth);
  const { activeTab, burgerMenuItems } = useAppSelector((state: RootState) => state.global);

  useEffect(() => {
    return () => {
      enableBodyScroll();
    };
  }, []);

  useEffect(() => {
    if (ref?.current) {
      if (isOpen) {
        disableBodyScroll();
      } else {
        enableBodyScroll();
      }
    } else {
      enableBodyScroll();
    }
  }, [isOpen, ref?.current]);

  const disableBodyScroll = () => {
    const html = document.querySelector('html');
    html && (html.style.overflow = 'hidden');
  };

  const enableBodyScroll = () => {
    const html = document.querySelector('html');
    html && (html.style.overflow = 'unset');
  };

  const openHamburgerMenu = () => {
    setIsOpen(true);
  };

  const closeHamburgerMenu = () => {
    setIsOpen(false);
  };

  const openLoginModal = () => {
    closeHamburgerMenu();
    dispatch(toggleLoginModal(true));
  };

  const goToProfile = useCallback(() => {
    if (isAuthenticated && user) {
      closeHamburgerMenu();
      navigate(RoutesEnum.DASHBOARD);
    }
  }, [isAuthenticated, user]);

  const onLogutClick = () => {
    closeHamburgerMenu();
    dispatch(logout());
  };

  return (
    <BurgerMenuWrapper ref={ref}>
      {isOpen ? (
        <div onClick={closeHamburgerMenu}>
          <img src={CloseIcon} alt="Menu" width={'22px'} height={'22px'} />
        </div>
      ) : (
        <div onClick={openHamburgerMenu}>
          <img src={MenuIcon} alt="Menu" width={'22px'} height={'22px'} />
        </div>
      )}

      <Menu right styles={styles} width={'65%'} isOpen={isOpen} onOpen={openHamburgerMenu} onClose={closeHamburgerMenu}>
        {!isAuthenticated && (
          <Flex flexDirection="column">
            <MenuItemWrapper onClick={closeHamburgerMenu} sideMargins="0px">
              <a href="https://www.psihoterapija.blog" target="_blank" rel="noreferrer">
                <Text variant="paragraph1" text={'Blog'} />
              </a>
            </MenuItemWrapper>
            {getAppRoutes(t).map((r, index) => {
              if (r.inBurgerMenu) {
                return (
                  <MenuItemWrapper key={index} onClick={closeHamburgerMenu} sideMargins="0px">
                    <NavLink to={r.path}>
                      <Text text={r.name} />
                    </NavLink>
                  </MenuItemWrapper>
                );
              }
            })}
          </Flex>
        )}

        {isAuthenticated &&
          burgerMenuItems?.map((item, index) => {
            if (Object.prototype.hasOwnProperty.call(item, 'customContent')) {
              return <CustomMenuItem key={index}>{item.customContent}</CustomMenuItem>;
            }

            return (
              <MenuItemWrapper
                key={index}
                onClick={() => {
                  window.scrollTo(0, 0);
                  item.action();
                  closeHamburgerMenu();
                }}
              >
                <Flex
                  width={'100%'}
                  padding={'10px 0'}
                  borderRadius={'20px'}
                  alignItems={'center'}
                  backgroundColor={item.tab === activeTab ? theme.colors.palette.purple : theme.colors.palette.transparent}
                >
                  <FontAwesomeIcon
                    size="lg"
                    width={50}
                    height={40}
                    icon={item.icon}
                    color={item.tab === activeTab ? theme.colors.palette.white : theme.colors.palette.black}
                  />
                  <Text
                    variant={'paragraph1'}
                    text={item.title}
                    color={item.tab === activeTab ? theme.colors.palette.white : theme.colors.palette.black}
                  />
                </Flex>
              </MenuItemWrapper>
            );
          })}

        <MenuItemWrapper>
          {isAuthenticated && (
            <Flex flexDirection="column" gap="8px" margin="24px 0 0 0">
              <Button variant={'primaryDefault'} text={t('btn_profile')} width={'100%'} onClick={goToProfile} />
              <Button variant={'secondaryDefault'} text={t('btn_logout')} width={'100%'} onClick={onLogutClick} />
            </Flex>
          )}

          {!isAuthenticated && <Button variant={'primaryDefault'} text={t('btn_login')} width={'100%'} onClick={openLoginModal} />}
        </MenuItemWrapper>
      </Menu>
    </BurgerMenuWrapper>
  );
};
