import React, { useRef, useState, useEffect, useMemo, useContext } from 'react';
import MasterCardIcon from 'src/assets/images/monri/mastercard.png';
import MaestroIcon from 'src/assets/images/monri/maestro.png';
import VisaIcon from 'src/assets/images/monri/visa.jpg';
import MonriIcon from 'src/assets/images/monri/monri.png';
import { RootState, useAppSelector } from 'src/store';
import { useTheme } from 'styled-components';
import { Button, Modal, Text, Grid, Flex, Input } from 'src/components/common';
import { useTranslation } from 'react-i18next';
import { CheckBox, ToggleButtonLabel, ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { InlineTextWrapper } from 'src/components/shared';
import { MonriPaymentForm } from 'src/pages/book-session/MonriPaymentForm';
import { IClientSecretResponseDTO } from 'src/api/monri/ResponseDtos';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { monriAPI } from 'src/api/monri/ApiRequests';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { CompanyVoucher } from 'src/types/CompanyVoucher';
import { companiesAPI } from 'src/api/companies/ApiRequests';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';

export interface PaymentModalProps {
  isOpen: boolean;
  amount?: number;
  orderNumber: string;
  enableVoucher: boolean;
  onClose?: () => void;
  paymentSuccessful: (orderNumber: string, panToken: string, voucher: CompanyVoucher) => void;
  payWithVoucher: (voucher: string) => void;
}

export const PaymentModal = ({
  isOpen,
  amount,
  orderNumber,
  enableVoucher,
  onClose,
  paymentSuccessful,
  payWithVoucher,
}: PaymentModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState<boolean>(true);
  const [clientSecret, setClientSecret] = useState<IClientSecretResponseDTO>();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [voucher, setVoucher] = useState<CompanyVoucher>(null);
  const [voucherCode, setVoucherCode] = useState<string>();
  const [voucherLoading, setVoucherLoading] = useState<boolean>(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (amount != null && amount > 0) {
      const getClientSecret = async (amount: number) => {
        setLoading(true);
        const response = await monriAPI.getClientSecret({ amount, orderNumber });
        setClientSecret(response.data);
        setLoading(false);
      };
      getClientSecret(amount);
    }
  }, [amount]);

  const isPayEnabled = useMemo(() => {
    if (voucher == null) {
      return acceptedTerms && clientSecret && !loading;
    } else {
      return acceptedTerms && !loading;
    }
  }, [acceptedTerms, clientSecret, loading]);

  const isInterntionalUser = useMemo(() => {
    return user?.country != 'Bosna i Hercegovina';
  }, [user]);

  const handlePaymentRsult = (orderNumber: string, panToken: string) => {
    setLoading(false);
    paymentSuccessful(orderNumber, panToken, voucher);
  };

  const payClicked = () => {
    setLoading(true);

    if (voucher == null) {
      handleCreditCard();
    } else {
      handleVoucher();
    }
  };

  const handleCreditCard = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const handleVoucher = () => {
    payWithVoucher(voucherCode);
  };

  async function validateVoucher() {
    setVoucherLoading(true);

    try {
      const response = await companiesAPI.validateVoucher({
        voucher: voucherCode,
      });
      if (response.success) {
        if (response.data.discount == 100) {
          handleVoucher();
        } else {
          setVoucher(response.data);
        }
      } else {
        setVoucherCode('');
        ToastNotifications.error(parseError(response.error), 'validateVoucher');
      }
    } catch (error) {
      setVoucherCode('');
      ToastNotifications.error(parseError(error), 'validateVoucher');
    } finally {
      setVoucherLoading(false);
    }
  }

  const getDiscount = () => {
    return voucher != null ? ` uz ${voucher.discount}% popusta` : '';
  };

  const calculatePrice = () => {
    const discount = voucher != null ? Number(voucher.discount) / 100 : 0;
    const discounted = amount * (1 - discount);
    const bam = discounted.toFixed(2);
    const eur = Number(Number(bam) / 1.95583).toFixed(2);
    return isInterntionalUser ? eur : bam;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Text variant={'heading5'} text={t('pay_for_package_1')} margin={'0 0 24px 0'} />

      <MonriPaymentForm formRef={formRef} clientSecret={clientSecret} paymentSuccessful={handlePaymentRsult} />

      {voucher == null && enableVoucher && (
        <Flex flexDirection={'row'} gap={'8px'} alignItems={'center'} margin={'0 0 24px 0'}>
          <Input
            width={'100%'}
            label={'VAUČER'}
            placeholder={'Ukoliko imate voucher unesite ga ovdje'}
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
          />
          <Button
            variant={'primaryDefault'}
            margin={'16px 0 0 0'}
            text={t('btn_use_voucher')}
            isLoading={voucherLoading}
            onClick={validateVoucher}
          />
        </Flex>
      )}

      <Flex flexDirection={'column'} width={'100%'}>
        <ToggleButtonWrapper style={{ marginBottom: 24 }}>
          <CheckBox id="checkbox" type="checkbox" checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />
          <ToggleButtonLabel htmlFor="checkbox" />
          <InlineTextWrapper>
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('book_therapist_2')} />
            <Text
              margin="0 4px 0 0"
              variant={'paragraph1'}
              text={t('book_therapist_3')}
              color={theme.colors.palette.purple}
              style={{ textDecoration: 'underline' }}
              onClick={() => goToUrl(config.PAYMENT_POLICY_URL, '_blank')}
            />
          </InlineTextWrapper>
        </ToggleButtonWrapper>

        <Button
          width={'100%'}
          margin={'32px 0 32px 0'}
          variant={'primaryDefault'}
          text={`${t('btn_confirm')} (${calculatePrice()} ${user?.country !== 'Bosna i Hercegovina' ? 'EUR' : 'BAM'}${getDiscount()})`}
          isLoading={loading}
          disabled={!isPayEnabled}
          onClick={payClicked}
        />

        <Grid gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 1fr'} gridTemplateRows={'1fr'} gap={'10px'} justifyContent={'center'}>
          <Flex />
          <a href="https://www.mastercard.hr/hr-hr.html" target="_blank" rel="noreferrer">
            <img src={MasterCardIcon} alt="mastercard-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://brand.mastercard.com/brandcenter/more-about-our-brands.html" target="_blank" rel="noreferrer">
            <img src={MaestroIcon} alt="maestro-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://www.visaeurope.com" target="_blank" rel="noreferrer">
            <img src={VisaIcon} alt="visa-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://monri.com" target="_blank" rel="noreferrer">
            <img src={MonriIcon} alt="monri-icon" height={isMobile ? 30 : 40} />
          </a>
          <Flex />
        </Grid>
      </Flex>
    </Modal>
  );
};
